<template>
  <div class="sync-redirect" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)">
    <div class="sync-redirect-header">
      <div class="sync-redirect-header-left flex items-center">
        <img src="@images/components/login/logo.png" />
        <span>青鹿教学云平台</span>
      </div>
      <div class="sync-redirect-header-right">
        <span>当前服务器版本：{{ server.version }}</span>
      </div>
    </div>
    <div class="sync-redirect-main">
      <AnhuiLogin v-if="redirect === 'anhui'" @login="handleLogin" />
      <QuantongLogin v-if="redirect === 'quantong'" @back="handleBack" @login="handleLogin" />
      <AllConnectLogin v-if="redirect === 'ava'" @back="handleBack" />
      <qxlManageLogin v-if="redirect === 'qxl'" @back="handleBack" />
    </div>
    <copyrightHtml />
  </div>
</template>

<script>
// api
import { getLicense } from '@/api/common';
// components
import copyrightHtml from '@/components/copyrightHtml';
import AnhuiLogin from './components/Anhui-login';
import QuantongLogin from './components/quantong-login';
import AllConnectLogin from './components/all-connect-login';
import qxlManageLogin from './components/qxl-manage-login';
// mixins
import loginDispatchMixin from '@/mixins/loginDispatch';

export default {
  name: 'sync-redirect',
  mixins: [loginDispatchMixin],
  components: {
    AnhuiLogin,
    QuantongLogin,
    AllConnectLogin,
    qxlManageLogin,
    copyrightHtml,
  },
  data() {
    return {
      isLoading: false,

      // 当前服务器信息
      server: {
        version: '--',
        id: '',
      },

      // 登录跳转来源(anhui-安徽 ningxia-宁夏 quantong-全通 ava-全连接)
      redirect: '',
    };
  },
  methods: {
    // 获取系统版本
    getLicense() {
      getLicense().then((res) => {
        this.server = res.result;
      });
    },
    // handle 登录
    handleLogin() {
      this.$router.replace('/home');
    },
    // handle 返回登录页
    async handleBack() {
      await this.$store.dispatch('user/resetToken');
      this.$router.replace('/login');
    },
  },
  mounted() {
    this.getLicense();
  },
  created() {
    const routeQuery = this.$route.query;
    this.isLoading = true;

    if (Object.prototype.hasOwnProperty.call(routeQuery, 'jsessionid')) {
      // 登录成功
      const { jsessionid, thirdProjectCode } = this.$route.query;
      this.$store.commit('user/SET_TOKEN', jsessionid);
      if (thirdProjectCode) {
        // 第三方登录
        thirdProjectCode === 'ningxia' && localStorage.setItem('ningxiaToken', jsessionid); // 宁夏平台登录（用于登出宁夏平台）
        this.handleLogin();
      } else {
        // 青鹿（云/校）登录
        this.loginDispatch();
      }
    }

    if (Object.prototype.hasOwnProperty.call(routeQuery, 'login_error')) {
      // 登录失败
      const { msg } = this.$route.query;
      this.$message.error(msg);
      this.handleBack();
    }

    if (Object.prototype.hasOwnProperty.call(routeQuery, 'thirdProjectCode')) {
      // 页面跳转
      this.isLoading = false;
      const { thirdProjectCode } = this.$route.query;
      this.redirect = thirdProjectCode;
    }
  },
};
</script>

<style lang="scss" scoped>
.sync-redirect {
  position: relative;
  height: 100vh;
  min-height: 780px;
  background: #1e232d;
  .sync-redirect-header {
    display: flex;

    &-left {
      margin-top: 16px;
      margin-left: 40px;

      & > img {
        height: 38px;
        margin-right: 8px;
      }

      & > span {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    &-right {
      flex: 1;
      margin-top: 20px;
      margin-right: 40px;
      text-align: right;
      color: #8b8f8f;
    }
  }

  .sync-redirect-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 54px);
    padding-bottom: 115px;
  }
}
</style>
